const bodyeEle = document.querySelector('body');
const hamburgerEle = document.querySelector('.js-hamburgerEle');
const headerNavEle = document.querySelector('.js-headerNav');
const IS_CLOSE = 'is-close';
const IS_ACTIVE = 'is-active';
const IS_VIEW = 'is-view';
const IS_FIXED = 'is-fixed';

export function hamburger() {
  hamburgerEle.addEventListener('click', () => {
    if (hamburgerEle.classList.contains('is-active')) {
      hamburgerEle.classList.add(IS_CLOSE);
      hamburgerEle.classList.remove(IS_ACTIVE);
      headerNavEle.classList.remove(IS_VIEW);
      bodyeEle.classList.remove(IS_FIXED);
    } else {
      hamburgerEle.classList.remove(IS_CLOSE);
      hamburgerEle.classList.add(IS_ACTIVE);
      headerNavEle.classList.add(IS_VIEW);
      bodyeEle.classList.add(IS_FIXED);
    }
  });
}

export function headerNavClose() {
  const headerNavList = document.querySelectorAll('.js-headerNavList');

  headerNavList.forEach((target) => {
    target.addEventListener('click', () => {
      const windowWidth = window.innerWidth;

      if (windowWidth < 768) {
        hamburgerEle.classList.add(IS_CLOSE);
        hamburgerEle.classList.remove(IS_ACTIVE);
        headerNavEle.classList.remove(IS_VIEW);
        bodyeEle.classList.remove(IS_FIXED);
      }
    });
  });
}
