import { throttle } from 'lodash';

const headerEle = document.querySelector('.js-headerEle');
const heroBgEle = document.querySelector('.js-heroBgEle');
const IS_SCROLLED = 'is-scrolled';

export function scrollAction() {
  window.addEventListener(
    'scroll',
    _.throttle(
      (e) => {
        headerScrolled();
        badgeViewer();
        return false;
      },
      100,
      { trailing: true, leading: true }
    )
  );

  window.addEventListener(
    'resize',
    _.throttle(
      (e) => {
        headerScrolled();
        badgeViewer();
        return false;
      },
      100,
      { trailing: true, leading: true }
    )
  );

  // ページ読み込み時にスクロールトップボタンがkvより下にあれば表示
  window.addEventListener('load', () => {
    headerScrolled();
    badgeViewer();
  });
}

// 間引きしたい処理
function headerScrolled() {
  const scroll = window.scrollY;

  if (document.querySelector('.js-heroBgEle')) {
    // スクロール量がkvの高さを超えたらページトップボタンを表示
    if (scroll > 200) {
      headerEle.classList.add(IS_SCROLLED);
      heroBgEle.classList.add(IS_SCROLLED);
    } else {
      headerEle.classList.remove(IS_SCROLLED);
      heroBgEle.classList.remove(IS_SCROLLED);
    }
  }
}

function badgeViewer() {
  if (document.querySelector('.js-contact')) {
    const contactEle = document.querySelector('.js-contact');
    const badgeEle = document.querySelector('.grecaptcha-badge');

    if (contactEle.classList.contains('is-animated')) {
      badgeEle.classList.add('is-animated');
      console.log('add');
    } else {
      badgeEle.classList.remove('is-animated');
      console.log('remove');
    }
  }
}
