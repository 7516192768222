import Aos from 'aos';
import { hamburger, headerNavClose } from './modules/hamburger';
import { heroAction } from './modules/heroAction';
import { scrollAction } from './modules/scrollAction';
import { anchorSmooth } from './modules/smooth';
import { scrollmagic } from './modules/scrollmagic';
import { contactInputCheck } from './modules/contact';

contactInputCheck();

anchorSmooth();
hamburger();
headerNavClose();
scrollAction();
heroAction();
scrollmagic();

Aos.init({
  duration: 1000,
});
