export function heroAction() {
  const heroIntroEle = document.querySelector('.js-heroIntro');
  const heroLogoEle = document.querySelector('.js-heroLogo');

  window.addEventListener('load', () => {
    if (document.querySelector('.js-heroLogo')) {
      heroLogoEle.classList.add('is-move');
    }
  });
}
