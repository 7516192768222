export function contactInputCheck() {
  if (document.getElementById('backbtn')) {
    const backBtn = document.getElementById('backbtn');
    const urlEle = window.location.href;
    const createUrl = 'https://raise07.com/#contact';
    console.log(createUrl);

    backBtn.addEventListener('click', () => {
      setTimeout(() => {
        window.location.href = createUrl;
      }, 100);
    });
  }

  // window.addEventListener('load', () => {
  //   const inputEle = document.querySelectorAll('.p-contactForm_input');
  //   const contactId = document.getElementById('contact');
  //   let count = 0;

  //   inputEle.forEach((target) => {
  //     console.log(inputEle.length);

  //     console.log(target.detail.inputs.value);

  //     if (count < inputEle.length) {
  //       if (!target.classList.contains('.wpcf7-not-valid')) {
  //         count++;
  //       }
  //     }
  //   });

  //   if (inputEle.length == count) {
  //     console.log('count' + count);
  //     contactId.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });

  //     document.addEventListener(
  //       'wpcf7submit',
  //       function (event) {
  //         var inputs = event.detail.inputs;

  //         for (var i = 0; i < inputs.length; i++) {
  //           console.log(inputs[i].value);
  //           if ('yourname' == inputs[i].name) {
  //             alert(inputs[i].value);
  //             break;
  //           }
  //         }
  //       },
  //       false
  //     );
  //   }
  // });
}
