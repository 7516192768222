import ScrollMagic from 'scrollmagic';

export function scrollmagic() {
  if (document.querySelector('.js-scrollAnimation')) {
    const controller = new ScrollMagic.Controller();

    const fadeIn = document.querySelectorAll('.js-scrollAnimation');

    for (let i = 0; i < fadeIn.length; i++) {
      const tag = fadeIn[i];

      new ScrollMagic.Scene({
        triggerElement: tag,
        triggerHook: 'onCenter',
        offset: 0,
      })
        .setClassToggle(tag, 'is-animated')
        .addTo(controller);
    }
  }

  if (document.querySelector('.js-scrollAnimationEnter')) {
    const controllerEnter = new ScrollMagic.Controller();

    const fadeIn = document.querySelectorAll('.js-scrollAnimationEnter');

    for (let i = 0; i < fadeIn.length; i++) {
      const tag = fadeIn[i];

      new ScrollMagic.Scene({
        triggerElement: tag,
        triggerHook: 'onEnter',
        offset: 0,
      })
        .setClassToggle(tag, 'is-animated')
        .addTo(controllerEnter);
    }
  }
}
